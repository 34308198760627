<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Categories
			</div>
			<V2Button class="page__info--btn" to="/admin/knowledgebase/categories/create">
				Add category
			</V2Button>
		</div>

		<div>
			<table-view
				:table="getTable"
				:showSearch="false"
				:showPagination="false"
			/>
		</div>
	</div>
</template>

<script>
	import TableView   from '@/components/ui/views/tableView/TableView';
	import api         from '@/services/api';
	import V2Button           from '@/components/v2/ui/V2Button';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Categories'
		},
		components: {
			TableView,
			V2Button
		},
		data: () => ({
			categories: []
		}),
		computed: {
			getTable () {
				return {
					headings: [
						'Title'
					],
					rows: this.categories.map((category) => {
						return {
							route: `/admin/knowledgebase/categories/${category.id}`,
							columns: [
								category.title
							]
						};
					})
				};
			},
			getHeaderAction () {
				return {
					text: 'Add category',
					route: '/admin/knowledgebase/categories/create'
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/knowledgebase',
						text: 'Knowledgebase'
					},
					{
						path: '/admin/knowledgebase/categories',
						text: 'Categories'
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setCategories();
		},
		methods: {
			async setCategories () {
				const categories = await api.admin.getAllKnowledgebaseCategories();
				if (!categories) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.categories = categories;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>

<style lang="scss" scoped>

.page {
  &__info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    &--title {
      width: fit-content;
    }

    &--btn {
      width: fit-content;
    }
  }
}

</style>
